<!-- login -->
<template>
  <div class="login-page" :style="{backgroundImage: `url(${personalization.logonPage.backgroundMap})`}">
    <ics-page-login-header :project-title="projectTitle" :layout="[]" />
    <div class="login-main">
      <div class="login-pic" />
      <div class="login-inner" :style="[{backgroundColor: `${personalization.logonPage.backgroundColor}`},{border: `1px solid ${$store.state.logonBackgroundColor}` }]">
        <div class="">
          <div class="logo-inner">
            <img :src="personalization.logonPage.logo">
          </div>
          <h2>{{ projectTitle }}</h2>
        </div>
        <div class="login-form">
          <form
            ref="login_form"
            @submit.prevent="loginSubmit"
          >
            <div class="form-item">
              <input v-model="form.account" type="text" placeholder="请输入手机号">
            </div>
            <div class="form-item">
              <input v-model="form.password" type="password" placeholder="请输入密码">
            </div>
            <div class="form-item">
              <input v-model="form.code" style="width: 58%;height: 85%;vertical-align: middle;" type="number" placeholder="请输入验证码">
              <debounce-button v-if="showTime === false" :loading="loading.smsCode" style="padding: 10px 5px !important;vertical-align: middle;width: 92px;" @click="sendVerificationSms">
                获取验证码
              </debounce-button>
              <el-button v-else style="padding: 10px 15px !important;vertical-align: middle;">
                {{ time }}S后重发
              </el-button>
            </div>
            <div class="btn-inner-find-pwd">
              <a class="text-btn" href="javascript:" @click="$router.push({ name: 'findPassWord' })">忘记密码</a>
            </div>
            <div class="btn-inner">
              <button v-loading="loading.submit" :disabled="loading.submit" type="submit">
                登 录
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { cookie } from '@/assets/js/config'
import IcsPageLoginHeader from '@/components/page-login-header'
import api from '@/assets/js/api'
import axios from "axios";
export default {
  components: { IcsPageLoginHeader },
  data () {
    return {
      changeUrl: '',
      projectTitle: '',
      remember: false,
      loading: {
        smsCode: false,
        submit: false
      },
      personalization: {
        logonPage: {},
        global: {}
      },
      time: 300,
      showTime: false,
      form: {
        code: '',
        account: '',
        password: '',
        codeToken: ''
      },
    }
  },
  watch: {},
  created () {
    if (cookie.empMobile() !== undefined && cookie.empMobile() !== 'undefined') {
      this.form.account = cookie.empMobile()
      this.remember = true
    } else {
      this.remember = false
    }
    this.getConfigureDetail()
  },
  methods: {
    getConfigureDetail () {
      this.utils.getPersonalization(true)
      return new Promise((resolve, reject) => {
        this.utils.getPersonalization().then(result => {
          this.personalization = result
          this.projectTitle = result.global.wangName+process.env.VUE_APP_PROJECT_NAME
          cookie.setCookie('globalWangName', result.global.wangName+process.env.VUE_APP_PROJECT_NAME, this.remember)
          cookie.setCookie('globalLogo', result.global.logo, this.remember)
          // 动态修改ico
          let link = document.querySelector("link[rel*='icon']") || document.createElement('link')
          link.type = 'image/x-icon'
          link.rel = 'shortcut icon'
          link.href = result.global.logo
          document.getElementsByTagName('head')[0].appendChild(link)
          document.title = result.global.wangName+process.env.VUE_APP_PROJECT_NAME
          resolve()
        }).catch(e => {
          reject(e)
        })
      })
    },
    sendVerificationSms () {
      if (!/^1[3|4|5|7|8|9][0-9]\d{8}$/.test(this.form.account) || !this.form.account) {
        this.$message.error('请输入正确的手机号')
        return false
      }
      if (!this.form.password) {
        this.$message.error('请输入密码')
        return false
      }
      this.loading.smsCode = true
      const data = {
        mobile: this.form.account,
        type: 5,
        password: this.form.password
      }
      this.api.open.sendSmsCode(data).then(result => {
        if (result.data.success === true) {
          this.$message({
            message: '发送成功',
            type: 'success'
          })
          this.showTime = true
          this.timer = setInterval(() => {
            if (this.time === 0) {
              clearInterval(this.timer)
              this.time = 300
              this.showTime = false
            } else {
              this.time--
            }
          }, 1000)
        } else {
          this.$message.error(result.data.message)
        }
      }).finally(() => {
        this.loading.smsCode = false
      })
    },
    loginSubmit () {
      const form = this.form
      if (!this.form.password) {
        this.$message.error('请输入密码')
        return false
      }
      if (this.form.password.length < 6) {
        this.$message.error('密码长度最低6位')
        return false
      }
      if (!this.form.code) {
        this.$message.error('请输入验证码')
        return false
      }
      /*if (!(/(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]/.test(this.form.password))) {
        this.$message.error('密码需要由数字和字母组成')
        return false
      }*/
      // if (!this.form.code) {
      //  this.$message.error('请输入验证码')
      //  return false
      // }
      this.loading.submit = true
      api.base.goLogin(form).then(result => {
        this.loading.submit = false
        cookie.setCookie('token', result.data.data.token, this.remember)
        cookie.setCookie('empAccount', result.data.data.account, this.remember)
        cookie.setCookie('empMobile', result.data.data.employeeInfo.mobile, this.remember)
        cookie.setCookie('empName', result.data.data.employeeInfo.name, this.remember)
        cookie.setCookie('empCode', result.data.data.employeeCode, this.remember)
        cookie.setCookie('appKey', result.data.data.appKey, this.remember)
        cookie.setCookie('visitAt', result.data.data.visitAt, this.remember)
        // 一定要重新刷新$store
        this.$store.commit('updateEmpInfo')
        //this.utils.getProvinceCityInfo(true)
        if (result.data.data.featureList.length > 0) {
          const featureList = result.data.data.featureList
          window.sessionStorage.setItem('featureList', JSON.stringify(featureList))
          this.$store.commit('updateFeatureList', featureList)
        }
        if (this.$route.query.redirecturl) {
          this.$router.push({ name: 'index' })
        } else {
          this.$router.push({ name: 'index' })
        }
      }).catch(e => {
        this.loading.submit = false
      })
      return false
    }
  }
}
</script>
<style lang="less" scoped>
.login-page {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: url("../assets/images/demo/login-big.png");
  background-size: 100% 100%;
}
.login-main {
  width: 1000px;
  height: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -500px;
  margin-top: -170px;
  .login-pic {
    float: left;
    width: 465px;
    height: 290px;
    background-size: cover;
    margin-top: 25px;
    margin-left: 70px;
  }
}
.login-inner {
  float: right;
  width: 300px;
  height: 400px;
  border-radius: 2px;
  box-sizing: border-box;
  padding: 40px 30px 40px;
  background-color: #fff;
  border: 1px solid #E8E8E8;
  margin-right: 50px;
  .logo-inner {
    margin: 0 auto 10px;
    width: 80px;
    height: 35px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  h2 {
    font-size:16px;
    line-height: 1;
    font-weight: 400;
    color: #666;
    text-align: center;
    margin-bottom: 30px;
  }
}
.login-form {
  .form-item {
    height: 40px;
    margin-bottom: 8px;
    input {
      width: 100%;
      height: 40px;
      padding: 5px;
      border: 1px solid #D9D9D9;
      box-sizing: border-box;
    }
  }
  .auth-code {
    display: inline-block;
    width: 146px;
    border-top: none;
    input{
      outline: none;
    }
  }
  .captcha-img {
    display: inline-block;
    margin-left: 6px;
    width: 80px;
    height: 40px;
    overflow: hidden;
    cursor: pointer;
    vertical-align: bottom;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .form-item:first-child {
    border-bottom: none;
  }
  .remember-inner {
    margin-top: 10px;
  }
  .btn-inner {
    margin-top: 20px;
    display: flex;
    button {
      width: 100%;
      height: 40px;
      line-height: 30px;
      border: none;
      border-radius:2px;
      background-color: #1D7FF2;
      margin-right: 8px;
      color: #fff;
      font-size: 16px;
      justify-content: space-around;
    }
    button:hover {
      background-color: darken(#66b1ff, 10%);
    }
  }
  .btn-inner-find-pwd{
    width: 88%;
    margin: 20px auto 0;
    text-align: right;
    font-size: 14px;
    a{
      color: #666 !important;
    }
  }
}

</style>
